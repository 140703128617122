import { Component, Inject } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { APP_NAME, ALLOW_REGISTRATION } from "../../tokens/app.tokens";

@Component({
  selector: "app-login",
  templateUrl: "./login.page.html",
  styleUrls: ["./login.page.scss"],
})
export class LoginPage {

  public year: string;

  constructor(
    @Inject(APP_NAME) public appName: string,
    @Inject(ALLOW_REGISTRATION) public allowRegistration: boolean,
    private router: Router,
    private readonly authService: AuthService,
    private toastController: ToastController,
  ) {
    const now = new Date();
  }

  async presentToast(text) {

    const toast = await this.toastController.create({
      message: text,
      duration: 2000,
    });
    toast.present();

  }

  async loginUser(value) {
    try {
      await this.authService.login(value);

      const profile = this.authService.getUserProfileStatic();
      // Navigate to the main page after successful login
      this.router.navigate(['/main']);
    } catch (err) {
      // Handle the error and show the message in the toast
      this.presentToast(err.message || 'Login failed');
    }
  }
}
