"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LibraryTask = void 0;
var LibraryTask = /** @class */ (function () {
    function LibraryTask() {
        this.id = "";
        this.name = "";
        this.code = "";
        this.external_id = "";
        this.unit_cost = 0;
        this.measured_unit = "";
        this.created_by = "";
        this.created_at = "";
        this.modified_by = "";
        this.modified_at = "";
    }
    return LibraryTask;
}());
exports.LibraryTask = LibraryTask;
