import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AlertController } from '@ionic/angular';
@Injectable({
    providedIn: 'root'
})
export class AppUpdateService {
    constructor(private readonly updates: SwUpdate,
        private alertCtrl: AlertController) {
        /*
    this.updates.available.subscribe(event => {
        this.showAppUpdateAlert();
    });
    */
    }
    showAppUpdateAlert() {
        const header = 'Uusi versio!';
        const message = 'Uudelleen käynnistä sovellus päivittääksesi sen.';
        const action = this.doAppUpdate;
        const caller = this;

        this.presentAlert(header, message, action, caller);
    }
    doAppUpdate() {
        this.updates.activateUpdate().then(() => document.location.reload());
    }

    async presentAlert(header, message, action, caller) {
        const alert = await this.alertCtrl.create({
            header: header,
            message: message,
            buttons: [
                {
                    text: 'OK',
                    role: "cancel",
                    handler: action,
                },
            ],
        });
        await alert.present();
    }
}