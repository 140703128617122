export const serviceAvailableIncountries = [
    { id: 'fi', name: "Suomi", timezone: 'Europe/Helsinki' },
];

export const availableLanguages = [
    { id: 'fi-FI', name: 'Finnish' },
    { id: 'en-US', name: 'English' },
];

export const getServiceAvailableCountryText = (id): string => {
    const a = serviceAvailableIncountries.find(a => a.id === id);
    return a.name;
}