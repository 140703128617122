import { InjectionToken, NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import BugsnagPerformance from '@bugsnag/browser-performance'
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../../../environments/environment';
import { provideAnalytics, getAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { AuthService, ENVIRONMENT, InstantSearchService, ProjectService, ROLE_CONTEXT, SubscriberService, UserService } from 'common';
import { RegisterFormComponent } from './components/register-form/register-form.component';
import { RegisterComponent } from './pages/register/register.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { build } from 'projects/build-number';
import { registerLocaleData } from '@angular/common';
import localeFi from '@angular/common/locales/fi';

registerLocaleData(localeFi, 'fi-FI');

if (environment.production === true) {
  Bugsnag.start({ apiKey: '81ad83f0887ed5707da7f6081f6ea476', appVersion: build.buildNumber });
  BugsnagPerformance.start({ apiKey: '69a7fbb101a1b8ab74d67f16d43c13c6' });
}

export function errorHandlerFactory() {
  if (environment.production === true) {
    return new BugsnagErrorHandler();
  } else {
    return new ErrorHandler();
  }
}

@NgModule({
  declarations: [
    AppComponent, RegisterComponent, RegisterFormComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatProgressBarModule,
    RecaptchaV3Module,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatIconModule,
    AppRoutingModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    MatInputModule,
    MatNativeDateModule,
    FormsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideStorage(() => getStorage())
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    ProjectService,
    AuthService,
    UserService,
    SubscriberService,
    { provide: ENVIRONMENT, useValue: environment },
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LfCM5YmAAAAAOGziMmoIOzupJL7g1xpDoYlPNp7" },
    { provide: ROLE_CONTEXT, useValue: ['admin', 'super-admin', 'free', 'lite'] },
    { provide: LOCALE_ID, useValue: 'fi-FI' } // Set Finnish locale globally


  ],
  bootstrap: [AppComponent]
})


export class AppModule { }