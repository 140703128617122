import { Injectable } from '@angular/core';
import { Firestore, collectionData, collection, addDoc, doc, deleteDoc, updateDoc, orderBy, query, where, limit, startAt, getDocs, startAfter } from '@angular/fire/firestore';
import { Receipt } from '@shared/models/receipt';
import { Observable } from 'rxjs';

@Injectable()
export class ReceiptService {

    private historyCursor = null;

    constructor(private firestore: Firestore) {
    }

    add(subscriberId: string, vehicle: Partial<Receipt>): any {
        addDoc(collection(this.firestore, `subscribers/${subscriberId}/receipts`), vehicle as Receipt);
    }

    update(subscriberId: string, receiptId: string, receipt: Receipt): any {
        updateDoc(doc(this.firestore, `subscribers/${subscriberId}/receipts/${receiptId}`), { ...receipt });
    }

    delete(subscriberId: string, vehicleId: string): any {
        deleteDoc(doc(this.firestore, `subscribers/${subscriberId}/receipts/${vehicleId}`));
    }

    getAllLive(subscriberId: string) {
        return collectionData(collection(this.firestore, `subscribers/${subscriberId}/receipts`), { idField: 'id' }) as Observable<Receipt[]>;
    }

    async getAll(subscriberId: string, userId: string, reset: boolean, state: string, limitQuery: number): Promise<Receipt[]> {
        const queryPath = `subscribers/${subscriberId}/receipts`;

        let q = null;
        if (this.historyCursor !== null && this.historyCursor !== undefined && reset == false) {
            q = query(collection(this.firestore, queryPath), where('user_id', '==', userId), where('state', '==', state), orderBy('date', 'desc'), limit(limitQuery), startAfter(this.historyCursor));
        }
        else {
            q = query(collection(this.firestore, queryPath), where('user_id', '==', userId), where('state', '==', state), orderBy('date', 'desc'), limit(limitQuery));
        }

        const data = await getDocs(q);
        const replySet: Receipt[] = [];

        if (data.docs.length > 0) {
            this.historyCursor = data.docs[data.docs.length - 1];

            data.docs.forEach(doc => {
                const receipt = doc.data() as Receipt;
                receipt.id = doc.id;
                replySet.push(receipt);
            });
        }

        return replySet;
    }

    async getAllByUser(subscriberId: string, userId: string, state: string = null): Promise<Receipt[]> {

        let q = null;
        if (state === null) {
            q = query(collection(this.firestore, `subscribers/${subscriberId}/receipts`), where('user_id', '==', userId), orderBy('date', 'desc'));
        }
        else {
            if (state === 'MODIFABLE') {
                q = query(collection(this.firestore, `subscribers/${subscriberId}/receipts`), where('user_id', '==', userId), where('state', 'in', ['APPROVED', 'CREATED']), orderBy('date', 'desc'));
            }
        }

        const data = await getDocs(q);
        const replySet: Receipt[] = [];

        if (data.docs.length > 0) {
            this.historyCursor = data.docs[data.docs.length - 1];

            data.docs.forEach(doc => {
                const o = Object.assign(new Receipt(), doc.data());
                o.id = doc.id;
                replySet.push(o);
            });
        }

        return replySet;
    }

    async getByProject(subscriberId: string, projectId: string): Promise<Receipt[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/receipts`), orderBy('date', 'desc'), where("project_id", "==", projectId));

        const data = await getDocs(q);

        const replySet: Receipt[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new Receipt(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }
}