"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackingDevice = void 0;
var TrackingDevice = /** @class */ (function () {
    function TrackingDevice() {
        this.latest_data = [];
        this.name = "";
        this.latest_analysis = [];
        this.subscription_ends = "";
        this.last_seen_time = "";
        this.last_seen_lat = 0.0;
        this.last_seen_lon = 0.0;
        this.subscription_id = "";
        this.subscriber_id = "";
        this.imsi = "";
        this.model = "";
        this.iccid = "";
        this.phone_number = "";
        this.description = "";
        this.created_at = "";
        this.created_by = "";
        this.last_transmission = "";
        this.modified_at = "";
        this.modified_by = "";
    }
    return TrackingDevice;
}());
exports.TrackingDevice = TrackingDevice;
