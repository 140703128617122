"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurchaseOrder = void 0;
var PurchaseOrder = /** @class */ (function () {
    function PurchaseOrder() {
        this.id = "";
        this.name = "";
        this.subscrtiber_id = "";
        this.description = "";
        this.business_id = "";
        this.status = "";
        this.tracker_unit_count = 0;
        this.created = "";
    }
    return PurchaseOrder;
}());
exports.PurchaseOrder = PurchaseOrder;
