import { Injectable } from '@angular/core';
import { Firestore, collectionData, collection, addDoc, doc, deleteDoc, updateDoc, getDocs, orderBy, query } from '@angular/fire/firestore';
import { UserTask } from '@shared/models';
import { setDoc } from 'firebase/firestore';
import { Observable } from 'rxjs';

@Injectable()
export class UserTaskService {

    constructor(private firestore: Firestore) { }

    // Method to add a new user task to Firestore
    async add(subscriberId: string, userId: string, task: UserTask): Promise<string> {
        const taskCollection = collection(this.firestore, `subscribers/${subscriberId}/users/${userId}/user-tasks`);
        const docRef = await addDoc(taskCollection, Object.assign({}, task));
        return docRef.id;
    }

    // Method to update an existing user task
    update(subscriberId: string, userId: string, taskId: string, task: UserTask): any {
        const taskDocRef = doc(this.firestore, `subscribers/${subscriberId}/users/${userId}/user-tasks/${taskId}`);
        return setDoc(taskDocRef, { ...task }, { merge: true });
    }

    // Method to delete a user task
    delete(subscriberId: string, userId: string, taskId: string): any {
        const taskDocRef = doc(this.firestore, `subscribers/${subscriberId}/users/${userId}/user-tasks/${taskId}`);
        return deleteDoc(taskDocRef);
    }

    // Method to get all user tasks, ordered by a specific field
    async getAll(subscriberId: string, userId: string): Promise<UserTask[]> {
        const taskCollection = collection(this.firestore, `subscribers/${subscriberId}/users/${userId}/user-tasks`);
        const q = query(taskCollection, orderBy('name', 'desc'));  // Order by 'name' or any other relevant field
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as UserTask[];
    }

    // Method to subscribe to real-time updates for user tasks
    subscribeUserTasks(subscriberId: string, userId: string): Observable<UserTask[]> {
        const taskCollection = collection(this.firestore, `subscribers/${subscriberId}/users/${userId}/user-tasks`);
        const q = query(taskCollection);
        return collectionData(q, { idField: 'id' }) as Observable<UserTask[]>;
    }

    // Method to get user tasks as a one-time fetch
    async getUserTasks(subscriberId: string, userId: string): Promise<UserTask[]> {
        const taskCollection = collection(this.firestore, `subscribers/${subscriberId}/users/${userId}/user-tasks`);
        const querySnapshot = await getDocs(taskCollection);
        return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as UserTask[];
    }
}
