"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkDay = void 0;
var WorkDay = /** @class */ (function () {
    function WorkDay() {
        this.id = "";
        this.type = "";
        this.user_id = "";
        this.start = "";
        this.end = "";
        this.description = "";
        this.approved = false;
        this.completed = false;
        this.productive = 0;
        this.non_worktime = 0;
        this.approved_by = "";
        this.approved_at = "";
        this.state = "";
        this.approver_comments = "";
        this.daily_allowance_type = "";
        this.daily_allowance_country = "";
        this.daily_allowance_amount = 0;
        this.workday_duration = 0;
    }
    return WorkDay;
}());
exports.WorkDay = WorkDay;
