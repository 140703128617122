"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Attribute = void 0;
var Attribute = /** @class */ (function () {
    function Attribute() {
        this.name = "";
        this.value = "";
    }
    return Attribute;
}());
exports.Attribute = Attribute;
