<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-icon (click)="back()" name="arrow-back" slot="start"></ion-icon>
    </ion-buttons>
    <ion-title>Luo tili</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="card-item-content">
    <p>Voit ottaa ProveIt ajopäiväkirjan käytöön ilmaiseksi luomalla tilin.</p>

    <form [formGroup]="form">
      <ion-item>
        <ion-input label-placement="stacked" formControlName="firstname" label="Etunimi"
          class="ion-text-left"></ion-input>
      </ion-item>

      <ion-item>
        <ion-input label-placement="stacked" formControlName="lastname" label="Sukunimi"
          class="ion-text-left"></ion-input>
      </ion-item>

      <ion-item>
        <ion-input label-placement="stacked" formControlName="email" label="Sähköposti"
          class="ion-text-left"></ion-input>
      </ion-item>

      <ion-item>
        <ion-input label-placement="stacked" formControlName="phone" label="Puhelin" class="ion-text-left"></ion-input>
      </ion-item>

      <ion-item class="country-item">
        <ion-select formControlName="country" interface="popover" label-placement="stacked" label="Maa">
          <ion-select-option *ngFor="let country of countries" [value]="country.id">
            {{ country.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item class="country-item">
        <ion-select formControlName="language" interface="popover" label-placement="stacked" label="Kieli">
          <ion-select-option *ngFor="let language of languages" [value]="language.id">
            {{ language.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-button expand="block" (click)="save()" [disabled]="!form.valid">Luo ilmainen tili</ion-button>
    </form>
  </div>
</ion-content>