import { Injectable } from '@angular/core';
import { Firestore, collectionData, collection, addDoc, doc, deleteDoc, updateDoc, orderBy, query, where, limit, startAt, getDocs, startAfter } from '@angular/fire/firestore';
import { Trip } from '@shared/models/trip';
import { Observable } from 'rxjs'

@Injectable()
export class TripService {

    private historyCursor = null;

    constructor(private firestore: Firestore) {
    }

    async add(subscriberId: string, trip: Partial<Trip>): Promise<string> {
        return await addDoc(collection(this.firestore, `subscribers/${subscriberId}/trips`), Object.assign({}, trip)).then(d => {
            return d.id;
        });
    }

    update(subscriberId: string, tripId: string, trip: Trip): any {
        updateDoc(doc(this.firestore, `subscribers/${subscriberId}/trips/${tripId}`), { ...trip });
    }

    delete(subscriberId: string, tripId: string): any {
        deleteDoc(doc(this.firestore, `subscribers/${subscriberId}/trips/${tripId}`));
    }

    getAllLive(subscriberId: string) {
        return collectionData(collection(this.firestore, `subscribers/${subscriberId}/trips`), { idField: 'id' }) as Observable<Trip[]>;
    }

    async getAllByUserPaged(subscriberId: string, userId: string, reset: boolean, queryLimit: number): Promise<Trip[]> {
        const queryPath = `subscribers/${subscriberId}/trips`;

        let q = null;
        if (this.historyCursor !== null && this.historyCursor !== undefined && reset == false) {
            q = query(collection(this.firestore, queryPath), where('user_id', '==', userId), where('classified', '==', true), orderBy('end_time', 'desc'), limit(queryLimit), startAfter(this.historyCursor));
        }
        else {
            q = query(collection(this.firestore, queryPath), where('user_id', '==', userId), where('classified', '==', true), orderBy('end_time', 'desc'), limit(queryLimit));
        }

        const data = await getDocs(q);
        const replySet: Trip[] = [];

        if (data.docs.length > 0) {
            this.historyCursor = data.docs[data.docs.length - 1];

            data.docs.forEach(doc => {
                const o = Object.assign(new Trip(), doc.data());
                o.id = doc.id;
                replySet.push(o);
            });
        }

        return replySet;
    }

    getAllNotClassified(subscriberId: string, userId: string, queryLimit: number): any {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/trips`), where('classified', '==', false), where('user_id', '==', userId), orderBy('entry_method', 'desc'), orderBy('end_time', 'desc'), where('entry_method', '!=', 'MANUAL'), limit(queryLimit));
        //@ts-ignore
        return collectionData(q, { idField: 'id' }) as Observable<Trip[]>;
    }

    async getAllByUser(subscriberId: string, userId: string): Promise<Trip[]> {

        const q = query(collection(this.firestore, `subscribers/${subscriberId}/trips`), where('user_id', '==', userId), orderBy('end_time', 'desc'));

        const data = await getDocs(q);
        const replySet: Trip[] = [];

        if (data.docs.length > 0) {
            this.historyCursor = data.docs[data.docs.length - 1];

            data.docs.forEach(doc => {
                const o = Object.assign(new Trip(), doc.data());
                o.id = doc.id;
                replySet.push(o);
            });
        }

        return replySet;
    }

    async getAllByProject(subscriberId: string, projectId: string): Promise<Trip[]> {

        const q = query(collection(this.firestore, `subscribers/${subscriberId}/trips`), where('project', '==', projectId), orderBy('end_time', 'desc'));

        const data = await getDocs(q);
        const replySet: Trip[] = [];

        if (data.docs.length > 0) {
            this.historyCursor = data.docs[data.docs.length - 1];

            data.docs.forEach(doc => {
                const o = Object.assign(new Trip(), doc.data());
                o.id = doc.id;
                replySet.push(o);
            });
        }

        return replySet;
    }

    async getAllByVehicle(subscriberId: string, vehicleId: string): Promise<Trip[]> {

        const q = query(collection(this.firestore, `subscribers/${subscriberId}/trips`), where('vehicle_id', '==', vehicleId), orderBy('end_time', 'desc'));

        const data = await getDocs(q);
        const replySet: Trip[] = [];

        if (data.docs.length > 0) {
            this.historyCursor = data.docs[data.docs.length - 1];

            data.docs.forEach(doc => {
                const o = Object.assign(new Trip(), doc.data());
                o.id = doc.id;
                replySet.push(o);
            });
        }

        return replySet;
    }
}