<ion-content>
  <ion-grid class="login-grid" style="max-width: 500px;">
    <ion-row justify-content-center>
      <ion-col>
        <ion-img src="/assets/logo.png" class="logo"></ion-img>
        <h2 style="margin-bottom: 30px;">{{appName}}</h2>

        <login-form (formData)="loginUser($event)" [allowRegistration]="allowRegistration"></login-form>

      </ion-col>
    </ion-row>
    <ion-row><a href="https://www.proveit.fi/terms-and-conditions">Käyttöehdot ja tietosuoja</a></ion-row>
  </ion-grid>

  <ion-label class="copyright"> &copy; {{year}} Procode Oy<br />
  </ion-label>
</ion-content>