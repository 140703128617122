import { Injectable } from '@angular/core';
import { Firestore, collection, getDocs } from '@angular/fire/firestore';
import { Service } from '@shared/models'; // Adjust the path as necessary
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ServiceService {
    private readonly collectionPath = 'services';

    constructor(private firestore: Firestore) { }

    // Get all services that are available
    getAll(): Observable<Service[]> {
        const serviceCollection = collection(this.firestore, this.collectionPath);
        return from(getDocs(serviceCollection)).pipe(
            map(snapshot =>
                snapshot.docs
                    .map(doc => {
                        const data = doc.data() as Omit<Service, 'id'>; // Exclude 'id' from Firestore data
                        return { id: doc.id, ...data }; // Include document ID
                    })
                    .filter(service => service.available) // Filter for available services
            )
        );
    }
}
