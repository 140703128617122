"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlertSet = void 0;
var AlertSet = /** @class */ (function () {
    function AlertSet() {
        this.id = "";
        this.name = "";
        this.type = "";
        this.alert_receivers = [];
        this.description = "";
    }
    return AlertSet;
}());
exports.AlertSet = AlertSet;
