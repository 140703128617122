"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetEvent = void 0;
var AssetEvent = /** @class */ (function () {
    function AssetEvent() {
        this.id = "";
        this.additional_information = {};
        this.description = "";
        this.title = "";
        this.asset_id = "";
        this.type = "";
        this.sub_type = "";
        this.datetime = "";
    }
    return AssetEvent;
}());
exports.AssetEvent = AssetEvent;
