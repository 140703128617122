import { Component, OnInit } from '@angular/core';
import { ToastController, ModalController, LoadingController } from '@ionic/angular';
import { Project, Receipt } from '@shared/models';
import { AuthService } from '../../services/auth.service';
import { ProjectService } from '../../services/project.service';
import { ReceiptService } from '../../services/receipt.service';
import { ReceiptDetailsComponent } from './receipt-details/receipt-details.component';

@Component({
  selector: 'app-receipts',
  templateUrl: './receipts.page.html',
  styleUrls: ['./receipts.page.css'],
})
export class ReceiptsPage implements OnInit {

  public receipts;
  public projects: Project[];
  public profile;
  public loading: boolean = true;
  public more: boolean = false;
  private limitQuery: number = 20;
  public loader: HTMLIonLoadingElement = null;

  constructor(
    private receiptService: ReceiptService,
    private authService: AuthService,
    private projectService: ProjectService,
    protected toastController: ToastController,
    protected modalController: ModalController,
    private loaderCtrl: LoadingController) {
  }

  async ngOnInit() {

  }

  async ionViewDidEnter() {

    this.profile = this.authService.getUserProfileStatic();

    await this.projectService.getAllByUserAcl(this.profile.subscriber_id, this.profile.user_id).then((data: Project[]) => {
      this.projects = data;
    }).catch(ex => {
      console.error(ex);
    }).finally(() => {
    });

    this.loadReceipts();
  }

  getProjectName(id: string) {
    const project = this.projects.find(a => a.id === id);

    if (project !== undefined) {
      return project.name;
    }
    else {
      return "N/A";
    }
  }

  loadReceipts() {

    this.showLoading('Ladataan kuitteja');

    this.receiptService.getAll(this.profile.subscriber_id, this.profile.user_id, true, 'CREATED', this.limitQuery).then((data: Receipt[]) => {

      const convertedData = data.map((d: Receipt) => {
        if (typeof d.date !== 'string') {
          //@ts-ignore
          d.date = d.date.toDate().toISOString();
        }

        return d;
      });

      if (convertedData.length > 0) {
        this.receipts = convertedData;
      }
      else {
        this.receipts = null;
      }

      if (data.length === this.limitQuery) {
        this.more = true;
      }
      else {
        this.more = false;
      }
    }).catch(ex => {
      console.error(ex);
    }).finally(() => {
      this.dismissLoading();
    });
  }

  loadMore() {

    this.showLoading('Ladataan kuitteja');

    this.receiptService.getAll(this.profile.subscriber_id, this.profile.user_id, false, 'CREATED', this.limitQuery).then((data: Receipt[]) => {

      if (data.length > 0) {
        console.log("Add more data");

        const convertedData = data.map((d: Receipt) => {
          if (typeof d.date !== 'string') {
            //@ts-ignore
            d.date = d.date.toDate().toISOString();
          }

          return d;
        });

        this.receipts = this.receipts.concat(convertedData);
        console.log(`Array length ${this.receipts.length}`)
      }
      if (data.length === this.limitQuery) {
        this.more = true;
      }
      else {
        this.more = false;
      }
    }).catch(ex => {
      console.error(ex);
    }).finally(() => {
      this.dismissLoading();
    });
  }

  async addNew() {
    const modal = await this.modalController.create({
      component: ReceiptDetailsComponent,
      componentProps: {
        projects: this.projects
      },
    });

    modal.present();

    await modal.onWillDismiss();

    this.loadReceipts();
  }

  async edit(receipt: Receipt) {
    const modal = await this.modalController.create({
      component: ReceiptDetailsComponent,
      componentProps: {
        receipt: receipt,
        projects: this.projects
      },
    });

    modal.present();

    await modal.onWillDismiss().then((response) => {
      if (response.data !== undefined && response.data.receipt !== undefined) {
        let index = this.receipts.findIndex((r: Receipt) => r.id === response.data.receipt.id);
        this.receipts[index] = response.data.receipt;
      }
    })
  }

  remove(id: string) {
    this.receiptService.delete(this.profile.subscriber_id, id);
    let index = this.receipts.findIndex((r: Receipt) => r.id === id);
    this.receipts.splice(index, 1);
  }

  compareWith(o1, o2) {
    return o1 === o2;
  }

  selectProject(e, receipt: Receipt) {
    receipt.project = e.detail.value;
    this.receiptService.update(this.profile.subscriber_id, receipt.id, receipt);
  }

  async showLoading(message: string) {
    this.loader = await this.loaderCtrl.create({
      message: message,
    });

    this.loading = true;
    this.loader.present();
  }

  dismissLoading() {
    if (this.loader) {
      this.loader.dismiss();
      this.loading = false;
    }
  }

}
