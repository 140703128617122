"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Asset = void 0;
var Asset = /** @class */ (function () {
    function Asset() {
        this.id = "";
        this.name = "";
        this.description = "";
        this.license_plate = "";
        this.user_id = "";
        this.user_name = "";
        this.tracking_device_ids = [];
        this.code = "";
        this.last_lat = 0.0;
        this.last_lon = 0.0;
        this.location_id = "";
        this.asset_subtype_id = "";
        this.team_id_list = [];
        this.required_permission_id_list = [];
        this.partner_id_list = [];
        this.asset_type = "";
        this.alert_set_ids = [];
        this.created_at = "";
        this.created_by = "";
        this.modified_at = "";
        this.modified_by = "";
        this.is_electric = false;
        this.company_vehicle = false;
        this.initial_km = 0;
        this.initial_hours = 0;
        this.last_known_km = 0;
        this.last_known_engine_hours = 0;
        this.last_known_received = "";
        this.create_drivelog = false;
        this.silent_mode = false;
        this.latest_events = [];
        this.latest_data = [];
        this.current_user = "";
        this.last_seen = "";
        this.active_alerts = [];
        this.default_project_id = "";
    }
    return Asset;
}());
exports.Asset = Asset;
