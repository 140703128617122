"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Location = void 0;
var Location = /** @class */ (function () {
    function Location() {
        this.id = "";
        this.name = "";
        this.project_id = "";
        this.type = "";
        this.sub_type = "";
        this.place_id = "";
        this.lat = 0.0;
        this.lon = 0.0;
        this.street_address = "";
        this.team_id_list = [];
        this.state = "";
        this.progress = 0;
        this.start_time = "";
        this.end_time = "";
        this.description = "";
        this.sla_id = "";
        this.geofence = [];
        this.created_by = "";
        this.created_at = "";
        this.modified_by = "";
        this.modified_at = "";
    }
    return Location;
}());
exports.Location = Location;
