"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Parameter = exports.ProjectType = void 0;
var ProjectType = /** @class */ (function () {
    function ProjectType() {
        this.id = '';
        this.name = [];
        this.availableInService = []; // Initialize as an empty array
        this.parameters = [];
        this.created_at = new Date().toISOString();
        this.created_by = '';
    }
    return ProjectType;
}());
exports.ProjectType = ProjectType;
var Parameter = /** @class */ (function () {
    function Parameter() {
        this.internalName = '';
        this.name = {};
        this.type = 'string';
        this.mandatory = false;
    }
    return Parameter;
}());
exports.Parameter = Parameter;
