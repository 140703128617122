import { Injectable } from '@angular/core';
import { Firestore, collection, addDoc, doc, deleteDoc, updateDoc, getDocs, orderBy, query, getDoc, where } from '@angular/fire/firestore';
import { Team } from '@shared/models/team';

@Injectable()
export class TeamService {

    constructor(private firestore: Firestore) {
    }

    async add(subscriberId: string, team: Team): Promise<string> {
        return await addDoc(collection(this.firestore, `subscribers/${subscriberId}/teams`), Object.assign({}, team)).then(d => {
            return d.id;
        });
    }

    update(subscriberId: string, teamId: string, team: Team): any {
        updateDoc(doc(this.firestore, `subscribers/${subscriberId}/teams/${teamId}`), { ...team });
    }

    delete(subscriberId: string, teamId: string): any {
        deleteDoc(doc(this.firestore, `subscribers/${subscriberId}/teams/${teamId}`));
    }

    async getTeamMembersMultipe(subscriberId: string, teamIds: string[]): Promise<string[]> {
        let replySet: string[] = [];

        for (const team of teamIds) {
            const ref = doc(this.firestore, `subscribers/${subscriberId}/teams/${team}`);
            const d = (await getDoc(ref)).data() as Team;
            replySet = replySet.concat(d.member_id_list);
        }

        return replySet;
    }

    async getAll(subscriberId: string): Promise<Team[]> {
        const queryPath = `subscribers/${subscriberId}/teams`;

        let q = query(collection(this.firestore, queryPath), orderBy('name', 'asc'));

        const data = await getDocs(q);

        const replySet: Team[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new Team(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

    async getTeamsByMemberId(subscriberId: string, memberId: string): Promise<Team[]> {
        const queryPath = `subscribers/${subscriberId}/teams`;

        // Create a query to get teams where the member ID is in the member_id_list
        const q = query(
            collection(this.firestore, queryPath),
            where('member_id_list', 'array-contains', memberId)
        );

        const data = await getDocs(q);

        const replySet: Team[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new Team(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }
}