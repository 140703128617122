<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-icon (click)="dismiss()" name="arrow-back" slot="start"></ion-icon>
    </ion-buttons>
    <ion-title>Tilaa uusi salasana</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-grid class="login-grid">
    <ion-row justify-content-center>
      <ion-col>

        <form class="form" [formGroup]="reset_form" (ngSubmit)="resetPassword(reset_form.value)">
          <ion-item>
            <ion-input type="text" formControlName="email" label="Sähköposti"></ion-input>
          </ion-item>
          <ion-button style="margin-top: 20px" class="submit-btn" type="submit" expand="full" fill="outline"
            [disabled]="!reset_form.valid">Tilaa</ion-button>

          <label class="error-message">{{errorMessage}}</label>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>