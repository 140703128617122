"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.User = void 0;
var User = /** @class */ (function () {
    function User() {
        this.id = "";
        this.firstname = "";
        this.lastname = "";
        this.name = "";
        this.phone = "";
        this.email = "";
        this.role = "";
        this.manager_id = "";
        this.home_base = "";
        this.language = "";
        this.permissions = [];
        this.features = [];
        this.created_by = "";
        this.created_at = "";
        this.modified_by = "";
        this.modified_at = "";
    }
    return User;
}());
exports.User = User;
