import { Injectable } from '@angular/core';
import { Firestore, doc, docSnapshots, setDoc, } from '@angular/fire/firestore';
import { UserPreferences } from '@shared/models/user-preferences';
import { Observable } from 'rxjs';

@Injectable()
export class UserPreferencesService {

    private path: string = "user-preferences";

    constructor(
        private firestore: Firestore) {
    }

    async update(userId: string, prefs: Partial<UserPreferences>): Promise<any> {
        setDoc(doc(this.firestore, `${this.path}/${userId}`), prefs as any,
            { merge: true });
    }

    getCurrent(userId: string): Observable<any> {
        return docSnapshots(doc(this.firestore, this.path, userId));
    }

}