import { Injectable } from '@angular/core';
import { Firestore, collectionData, collection, addDoc, doc, deleteDoc, updateDoc, orderBy, query, where, limit, startAt, getDocs, startAfter } from '@angular/fire/firestore';
import { CostReport } from '@shared/models/cost-report';
import { Observable } from 'rxjs';

@Injectable()
export class CostReportService {

    private historyCursor = null;

    constructor(private firestore: Firestore) {
    }

    async add(subscriberId: string, costReport: Partial<CostReport>): Promise<string> {
        return await addDoc(collection(this.firestore, `subscribers/${subscriberId}/cost-reports`), Object.assign({}, costReport)).then(d => {
            return d.id;
        });
    }

    update(subscriberId: string, costReportId: string, costReport: CostReport): any {
        updateDoc(doc(this.firestore, `subscribers/${subscriberId}/cost-reports/${costReportId}`), { ...costReport });
    }

    delete(subscriberId: string, costReportId: string): any {
        deleteDoc(doc(this.firestore, `subscribers/${subscriberId}/cost-reports/${costReportId}`));
    }

    getAllLive(subscriberId: string) {
        return collectionData(collection(this.firestore, `subscribers/${subscriberId}/cost-reports`), { idField: 'id' }) as Observable<CostReport[]>;
    }

    async getAll(subscriberId: string): Promise<CostReport[]> {
        const queryPath = `subscribers/${subscriberId}/cost-reports`;

        const q = query(collection(this.firestore, queryPath), orderBy('start_period', 'desc'));

        const data = await getDocs(q);
        const replySet: CostReport[] = [];

        if (data.docs.length > 0) {
            this.historyCursor = data.docs[data.docs.length - 1];

            data.docs.forEach(doc => {
                const costReport = doc.data() as CostReport;
                costReport.id = doc.id;
                replySet.push(costReport);
            });
        }

        return replySet;
    }
}