import { Injectable } from '@angular/core';
import { Firestore, collection, doc, getDoc, getDocs, setDoc, updateDoc, deleteDoc, addDoc } from '@angular/fire/firestore';
import { ProjectType } from '@shared/models';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProjectTypeService {
    private readonly collectionPath = 'project-types';

    constructor(private firestore: Firestore) { }

    // Get all project types
    getAll(): Observable<ProjectType[]> {
        const projectTypeCollection = collection(this.firestore, this.collectionPath);
        return from(getDocs(projectTypeCollection)).pipe(
            map(snapshot => snapshot.docs.map(doc => {
                const data = doc.data();
                return { id: doc.id, ...data } as ProjectType;
            }))
        );
    }

    // Get all project types available for a given service ID
    getAllAvailableForService(serviceId: string): Observable<ProjectType[]> {
        const projectTypeCollection = collection(this.firestore, this.collectionPath);
        return from(getDocs(projectTypeCollection)).pipe(
            map(snapshot =>
                snapshot.docs
                    .map(doc => {
                        const data = doc.data();
                        return { id: doc.id, ...data } as ProjectType;
                    })
                    .filter(projectType =>
                        projectType.availableInService.includes(serviceId) // Check if service ID is available
                    )
            )
        );
    }

    // Get a single project type by ID
    get(projectTypeId: string): Observable<ProjectType> {
        const projectTypeDoc = doc(this.firestore, `${this.collectionPath}/${projectTypeId}`);
        return from(getDoc(projectTypeDoc)).pipe(
            map(snapshot => {
                const data = snapshot.data();
                return data ? { id: snapshot.id, ...data } as ProjectType : null;
            })
        );
    }

    // Create a new project type
    async add(projectType: ProjectType): Promise<string> {
        return await addDoc(collection(this.firestore, this.collectionPath), Object.assign({}, projectType))
            .then(docRef => docRef.id);
    }

    // Update an existing project type by ID
    update(projectTypeId: string, projectType: Partial<ProjectType>): Observable<void> {
        const projectTypeDoc = doc(this.firestore, `${this.collectionPath}/${projectTypeId}`);
        return from(updateDoc(projectTypeDoc, { ...projectType }));
    }

    // Delete a project type by ID
    delete(projectTypeId: string): Observable<void> {
        const projectTypeDoc = doc(this.firestore, `${this.collectionPath}/${projectTypeId}`);
        return from(deleteDoc(projectTypeDoc));
    }
}
