"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionType = void 0;
var PermissionType = /** @class */ (function () {
    function PermissionType() {
        this.id = "";
        this.name = "";
        this.type = "";
        this.sub_type = "";
        this.description = "";
    }
    return PermissionType;
}());
exports.PermissionType = PermissionType;
