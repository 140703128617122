import { Injectable } from '@angular/core';
import { Firestore, collectionData, collection, addDoc, doc, deleteDoc, updateDoc, getDocs, orderBy, query, getDoc, where } from '@angular/fire/firestore';
import { Alert } from '@shared/models/alert';
import { Observable } from 'rxjs';


@Injectable()
export class AlertService {

    constructor(private firestore: Firestore) {
    }

    add(subscriberId: string, alertSet: Alert): any {
        addDoc(collection(this.firestore, `subscribers/${subscriberId}/alert`), Object.assign({}, alertSet));
    }

    update(subscriberId: string, alertSetId: string, alertSet: Alert): any {
        updateDoc(doc(this.firestore, `subscribers/${subscriberId}/alert/${alertSetId}`), { ...alertSet });
    }

    delete(subscriberId: string, alertSetId: string): any {
        deleteDoc(doc(this.firestore, `subscribers/${subscriberId}/alert/${alertSetId}`));
    }

    subscribeAll(subscriberId: string): any {
        return collectionData(collection(this.firestore, `subscribers/${subscriberId}/alert`), { idField: 'id' }) as Observable<Alert[]>;
    }

    async get(subscriberId: string, alertSetId: string): Promise<Alert> {
        const ref = doc(this.firestore, `subscribers/${subscriberId}/alert/${alertSetId}`);
        return Object.assign(new Alert(), (await getDoc(ref)).data());
    }

    async getAll(subscriberId: string): Promise<Alert[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/alert`), orderBy('alert_time', 'desc'),);

        const data = await getDocs(q);

        const replySet: Alert[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new Alert(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

    async getAlertsByType(subscriberId: string, type: string): Promise<Alert[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/alert`), orderBy('alert_time', 'desc'), where("type", "==", type));

        const data = await getDocs(q);

        const replySet: Alert[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new Alert(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }
}