import { Injectable } from '@angular/core';
import { Firestore, collectionData, collection, addDoc, doc, deleteDoc, updateDoc, where, query, setDoc, orderBy, getDocs } from '@angular/fire/firestore';
import { TrackingDevice } from '@shared/models/tracking-device';
import { Observable } from 'rxjs';

@Injectable()
export class TrackingDeviceService {

    constructor(private firestore: Firestore) {
    }

    update(trackingDeviceId: string, trackingDevice: TrackingDevice): any {
        setDoc(doc(this.firestore, `tracking-devices/${trackingDeviceId}`), { ...trackingDevice });
    }

    delete(trackingDeviceId: string): any {
        deleteDoc(doc(this.firestore, `tracking-devices/${trackingDeviceId}`));
    }

    subscribeAll(subscriberId: string): any {
        console.log(`Filter with key '${subscriberId}'`);
        const q = query(collection(this.firestore, `tracking-devices`), where('subscriber_id', '==', subscriberId));
        return collectionData(q, { idField: 'id' }) as Observable<TrackingDevice[]>;
    }

    async getAll(subscriberId: string): Promise<TrackingDevice[]> {

        const q = query(collection(this.firestore, `tracking-devices`), where('subscriber_id', '==', subscriberId));

        const data = await getDocs(q);

        const replySet: TrackingDevice[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new TrackingDevice(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

    async getAllGlobal(): Promise<TrackingDevice[]> {
        const q = query(collection(this.firestore, `tracking-devices`));
        const data = await getDocs(q);

        const replySet: TrackingDevice[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new TrackingDevice(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }
}