"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Company = void 0;
var Company = /** @class */ (function () {
    function Company() {
        this.id = "";
        this.name = "";
        this.description = "";
        this.business_id = "";
        this.e_invoice_address = "";
        this.e_invoice_operator = "";
        this.invoicing_email = "";
        this.delivery_address_street = "";
        this.delivery_address_zip = "";
        this.delivery_address_city = "";
        this.country = "";
        this.score = 0;
        this.type = "";
        this.timezone = "Europe/Helsinki";
        this.created_at = "";
        this.created_by = "";
        this.modified_at = "";
        this.modified_by = "";
    }
    return Company;
}());
exports.Company = Company;
