"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPermission = void 0;
var UserPermission = /** @class */ (function () {
    function UserPermission() {
        this.name = "";
        this.type_id = "";
        this.sub_type = "";
        this.notes = "";
        this.valid_from = "";
        this.valid_until = "";
    }
    return UserPermission;
}());
exports.UserPermission = UserPermission;
