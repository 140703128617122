import { Injectable } from '@angular/core';
import { Firestore, collectionData, collection, addDoc, doc, deleteDoc, updateDoc, orderBy, query, where, limit, startAt, getDocs, startAfter } from '@angular/fire/firestore';
import { TripReport } from '@shared/models/trip-report';
import { Observable } from 'rxjs';

@Injectable()
export class TripReportService {

    private historyCursor = null;

    constructor(private firestore: Firestore) {
    }

    async add(subscriberId: string, tripReport: Partial<TripReport>): Promise<string> {
        return await addDoc(collection(this.firestore, `subscribers/${subscriberId}/trip-reports`), Object.assign({}, tripReport)).then(d => {
            return d.id;
        });
    }

    update(subscriberId: string, tripReportId: string, tripReport: TripReport): any {
        updateDoc(doc(this.firestore, `subscribers/${subscriberId}/trip-reports/${tripReportId}`), { ...tripReport });
    }

    delete(subscriberId: string, tripReportId: string): any {
        deleteDoc(doc(this.firestore, `subscribers/${subscriberId}/trip-reports/${tripReportId}`));
    }

    getAllLive(subscriberId: string) {
        return collectionData(collection(this.firestore, `subscribers/${subscriberId}/trip-reports`), { idField: 'id' }) as Observable<TripReport[]>;
    }

    async getAll(subscriberId: string): Promise<TripReport[]> {
        const queryPath = `subscribers/${subscriberId}/trip-reports`;

        const q = query(collection(this.firestore, queryPath), orderBy('date', 'desc'));

        const data = await getDocs(q);
        const replySet: TripReport[] = [];

        if (data.docs.length > 0) {

            data.docs.forEach(doc => {
                const tripReport = doc.data() as TripReport;
                tripReport.id = doc.id;
                replySet.push(tripReport);
            });
        }

        return replySet;
    }
}