import { Injectable } from '@angular/core';
import { Firestore, collection, addDoc, doc, deleteDoc, updateDoc, query, getDocs, orderBy } from '@angular/fire/firestore';
import { Company } from '@shared/models/company';


@Injectable()
export class CompanyService {

    constructor(private firestore: Firestore) {
    }

    async add(subscriberId: string, company: Company): Promise<string> {
        return await addDoc(collection(this.firestore, `subscribers/${subscriberId}/companies`), Object.assign({}, company)).then(d => {
            return d.id;
        });
    }

    update(subscriberId: string, companyId: string, company: Company): any {
        updateDoc(doc(this.firestore, `subscribers/${subscriberId}/companies/${companyId}`), { ...company });
    }

    delete(subscriberId: string, companyId: string): any {
        deleteDoc(doc(this.firestore, `subscribers/${subscriberId}/companies/${companyId}`));
    }

    async getAll(subscriberId: string): Promise<Company[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/companies`), orderBy('name', 'asc'),);

        const data = await getDocs(q);

        const replySet: Company[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new Company(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }
}