import { Injectable } from '@angular/core';
import { Firestore, collection, doc, deleteDoc, getDoc, getDocs, orderBy, query, setDoc, updateDoc, where } from '@angular/fire/firestore';
import { WorkDay } from '@shared/models/work-day';

@Injectable()
export class WorkDayService {

    constructor(private firestore: Firestore) { }

    async get(subscriberId: string, userId: string, workDayId: string): Promise<WorkDay> {
        const ref = doc(this.firestore, `subscribers/${subscriberId}/work-days/${workDayId}`);
        return Object.assign(new WorkDay(), (await getDoc(ref)).data());
    }

    set(subscriberId: string, workDay: WorkDay): any {
        const start = new Date(workDay.start);
        const workDayId = `${workDay.user_id}_${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`;
        setDoc(doc(this.firestore, `subscribers/${subscriberId}/work-days/${workDayId}`), { ...workDay });
    }

    update(subscriberId: string, workDayId: string, workDay: WorkDay): any {
        updateDoc(doc(this.firestore, `subscribers/${subscriberId}/work-days/${workDayId}`), { ...workDay });
    }

    delete(subscriberId: string, workDayId: string): any {
        deleteDoc(doc(this.firestore, `subscribers/${subscriberId}/work-days/${workDayId}`));
    }

    // Updated getAll method with optional startDate filter
    async getAll(subscriberId: string, startDate?: Date): Promise<WorkDay[]> {
        let q;

        if (startDate) {
            // If startDate is provided, add a filter to the query
            q = query(
                collection(this.firestore, `subscribers/${subscriberId}/work-days`),
                where('start', '>=', startDate.toISOString()), // Only fetch workdays after the specified date
                orderBy('start', 'desc')
            );
        } else {
            // If no startDate is provided, fetch all workdays
            q = query(
                collection(this.firestore, `subscribers/${subscriberId}/work-days`),
                orderBy('start', 'desc')
            );
        }

        const data = await getDocs(q);

        const replySet: WorkDay[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new WorkDay(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

    async getAllOfMyTeam(subscriberId: string): Promise<WorkDay[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/work-days`), orderBy('start', 'desc'));
        const data = await getDocs(q);
        const replySet: WorkDay[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new WorkDay(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

    async getProductiveTimeSegments(subscriberId: string, userId: string): Promise<any[]> {
        // Define the start date to limit the results to only recent records (last 15 days)
        const today = new Date();
        const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 15);

        // Fetch workdays starting from the defined date
        const allWorkDays = await this.getAll(subscriberId, startDate);

        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth(); // 0-based (0 is January)

        // Define period boundaries for the current month
        const periodOneStart = new Date(currentYear, currentMonth, 1);
        const periodOneEnd = new Date(currentYear, currentMonth, 15, 23, 59, 59);
        const periodTwoStart = new Date(currentYear, currentMonth, 16);
        const periodTwoEnd = new Date(currentYear, currentMonth + 1, 0, 23, 59, 59); // 0th day of next month gives the last day of the current month

        let earlierProductive = 0;
        let currentProductive = 0;

        allWorkDays.forEach(workDay => {
            const workDayStart = new Date(workDay.start);
            if (workDay.user_id === userId) {
                if (workDayStart >= periodOneStart && workDayStart <= periodOneEnd) {
                    earlierProductive += workDay.productive;
                } else if (workDayStart >= periodTwoStart && workDayStart <= periodTwoEnd) {
                    currentProductive += workDay.productive;
                }
            }
        });

        const periods = [
            {
                period_type: 'earlier',
                period_start: periodOneStart.toISOString(),
                period_end: periodOneEnd.toISOString(),
                productive: earlierProductive
            },
            {
                period_type: 'current',
                period_start: periodTwoStart.toISOString(),
                period_end: periodTwoEnd.toISOString(),
                productive: currentProductive
            }
        ];

        return periods;
    }

}
