"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TripReport = void 0;
var TripReport = /** @class */ (function () {
    function TripReport() {
        this.id = "";
        this.date = "";
        this.start_period = "";
        this.end_period = "";
        this.user_id = "";
        this.comment = "";
        this.state = "";
        this.project_id = "";
        this.external_key = "";
        this.rides = [];
        this.report_path = "";
    }
    return TripReport;
}());
exports.TripReport = TripReport;
