import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ReceiptsPageRoutingModule } from './receipts-routing.module';

import { ReceiptsPage } from './receipts.page';
import { AccountingClassifierService } from '../../services/accounting-classifier.service';
import { ProjectService } from '../../services/project.service';
import { ReceiptService } from '../../services/receipt.service';
import { ReceiptDetailsComponent } from './receipt-details/receipt-details.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    ReceiptsPageRoutingModule
  ],
  providers: [ReceiptService, ProjectService, AccountingClassifierService],
  declarations: [ReceiptsPage, ReceiptDetailsComponent]
})
export class ReceiptsPageModule { }
