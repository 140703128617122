import { Injectable } from '@angular/core';
import { Firestore, collectionData, collection, addDoc, doc, deleteDoc, updateDoc, getDocs, orderBy, query, getDoc, where } from '@angular/fire/firestore';
import { PermissionType } from '@shared/models/permission-type';
import { Observable } from 'rxjs';

@Injectable()
export class PermissionTypeService {

    constructor(private firestore: Firestore) {
    }

    add(subscriberId: string, permissionType: PermissionType): any {
        addDoc(collection(this.firestore, `subscribers/${subscriberId}/permission-types`), Object.assign({}, permissionType));
    }

    update(subscriberId: string, permissionTypeId: string, permissionType: PermissionType): any {
        console.log("Updating");
        updateDoc(doc(this.firestore, `subscribers/${subscriberId}/permission-types/${permissionTypeId}`), { ...permissionType });
    }

    delete(subscriberId: string, permissionTypeId: string): any {
        deleteDoc(doc(this.firestore, `subscribers/${subscriberId}/permission-types/${permissionTypeId}`));
    }

    subscribeAll(subscriberId: string): any {
        return collectionData(collection(this.firestore, `subscribers/${subscriberId}/permission-types`), { idField: 'id' }) as Observable<PermissionType[]>;
    }

    async get(subscriberId: string, permissionTypeId: string): Promise<PermissionType> {
        const ref = doc(this.firestore, `subscribers/${subscriberId}/permission-types/${permissionTypeId}`);
        return Object.assign(new PermissionType(), (await getDoc(ref)).data());
    }

    async getAll(subscriberId: string): Promise<PermissionType[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/permission-types`), orderBy('name', 'desc'),);

        const data = await getDocs(q);

        const replySet: PermissionType[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new PermissionType(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

    async getPermissionTypesByType(subscriberId: string, type: string): Promise<PermissionType[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/permission-types`), orderBy('name', 'desc'), where("type", "==", type));

        const data = await getDocs(q);

        const replySet: PermissionType[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new PermissionType(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }
}