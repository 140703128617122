"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Alert = void 0;
var Alert = /** @class */ (function () {
    function Alert() {
        this.subscriber_id = "";
        this.subscription_id = "";
        this.alert_time = "";
        this.service = "";
        this.device_id = "";
        this.device_type = "";
        this.title = "";
        this.parameters = [];
        this.alert_type = "";
        this.asset_id = "";
        this.acked_at = "";
        this.acked_by = "";
        this.alert_level = "";
    }
    return Alert;
}());
exports.Alert = Alert;
