"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetEventType = void 0;
var AssetEventType = /** @class */ (function () {
    function AssetEventType() {
        this.id = "";
        this.name = "";
        this.type = "";
        this.sub_type = "";
        this.description = "";
        this.declaration = {};
    }
    return AssetEventType;
}());
exports.AssetEventType = AssetEventType;
