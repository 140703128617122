import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { Router } from '@angular/router';

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css'],
})
export class LoginFormComponent implements OnInit {

  @Input('allowRegistration') allowRegistration: boolean;

  @Output() formData: EventEmitter<{
    email: string;
    password: string;
  }> = new EventEmitter();

  form: any;
  constructor(
    private fb: UntypedFormBuilder,
    private router: Router) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  get email() {
    return this.form.get('email');
  }

  get password() {
    return this.form.get('password');
  }

  requestPassword() {
    this.router.navigateByUrl("password-reset");
  }

  createAccount() {
    console.log("createAccount called");
    this.router.navigateByUrl("registration").then(success => {
      console.log("Navigation successful:", success);
    }).catch(err => {
      console.error("Navigation error:", err);
    });
  }

  onSubmit() {
    this.formData.emit(this.form.value);
  }
}