"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Task = void 0;
var Task = /** @class */ (function () {
    function Task() {
        this.id = "";
        this.name = "";
        this.task_library_id = "";
        this.external_id = "";
        this.team_id_list = [];
        this.start_time = "";
        this.end_time = "";
        this.description = "";
        this.included_in_base_price = false;
        this.unit_cost = 0;
        this.measured_unit = "";
        this.progress = 0;
        this.max_units = 0;
        this.budgeted_duration = 0;
        this.track_progress = false;
        this.created_by = "";
        this.created_at = "";
        this.modified_by = "";
        this.modified_at = "";
    }
    return Task;
}());
exports.Task = Task;
