"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Media = void 0;
var Media = /** @class */ (function () {
    function Media() {
        this.name = "";
        this.description = "";
        this.path = "";
        this.content_type = "";
    }
    return Media;
}());
exports.Media = Media;
