"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserProject = void 0;
var UserProject = /** @class */ (function () {
    function UserProject() {
        this.id = "";
        this.project_id = "";
        this.project_name = "";
        this.project_code = "";
        this.description = "";
        this.team_id = "";
        this.team_name = "";
        this.team_code = "";
    }
    return UserProject;
}());
exports.UserProject = UserProject;
