"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarEvent = void 0;
var CalendarEvent = /** @class */ (function () {
    function CalendarEvent() {
        this.id = "";
        this.title = "";
        this.user_id_list = [];
        this.asset_id_list = [];
        this.project_id = "";
        this.start_time = "";
        this.end_time = "";
        this.description = "";
        this.multidays = false;
        this.created_at = "";
    }
    return CalendarEvent;
}());
exports.CalendarEvent = CalendarEvent;
