"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DriveLog = void 0;
var DriveLog = /** @class */ (function () {
    function DriveLog() {
        this.id = "";
        this.imei = "";
        this.start_time = "";
        this.end_time = "";
        this.start_lat = 0.0;
        this.start_lon = 0.0;
        this.end_lat = 0.0;
        this.end_lon = 0.0;
        this.start_odometer = 0;
        this.end_odometer = 0;
        this.user_id = "";
    }
    return DriveLog;
}());
exports.DriveLog = DriveLog;
