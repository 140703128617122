import { Injectable } from '@angular/core';
import { Firestore, collectionData, collection, addDoc, doc, deleteDoc, updateDoc, getDocs, orderBy, query, getDoc, where } from '@angular/fire/firestore';
import { AssetEvent } from '@shared/models/asset-event';
import { Observable } from 'rxjs';

@Injectable()
export class AssetEventService {

    constructor(private firestore: Firestore) {
    }

    async add(subscriberId: string, assetEvent: AssetEvent): Promise<string> {
        return await addDoc(collection(this.firestore, `subscribers/${subscriberId}/asset-events`), Object.assign({}, assetEvent)).then(d => {
            return d.id;
        });
    }

    update(subscriberId: string, assetEventId: string, assetEvent: AssetEvent): any {
        console.log("Updating");
        updateDoc(doc(this.firestore, `subscribers/${subscriberId}/asset-events/${assetEventId}`), { ...assetEvent });
    }

    delete(subscriberId: string, assetEventId: string): any {
        deleteDoc(doc(this.firestore, `subscribers/${subscriberId}/asset-events/${assetEventId}`));
    }

    subscribeAll(subscriberId: string): any {
        return collectionData(collection(this.firestore, `subscribers/${subscriberId}/asset-events`), { idField: 'id' }) as Observable<AssetEvent[]>;
    }

    async get(subscriberId: string, assetEventId: string): Promise<AssetEvent> {
        const ref = doc(this.firestore, `subscribers/${subscriberId}/asset-events/${assetEventId}`);
        return Object.assign(new AssetEvent(), (await getDoc(ref)).data());
    }

    async getAll(subscriberId: string): Promise<AssetEvent[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/asset-events`), orderBy('name', 'desc'),);

        const data = await getDocs(q);

        const replySet: AssetEvent[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new AssetEvent(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

    async getAssetEventsByAsset(subscriberId: string, asset: string): Promise<AssetEvent[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/asset-events`), orderBy('datetime', 'desc'), where("asset_id", "==", asset));

        const data = await getDocs(q);

        const replySet: AssetEvent[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new AssetEvent(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }
}