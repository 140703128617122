"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Event = void 0;
var Event = /** @class */ (function () {
    function Event() {
        this.subscriber_id = "";
        this.event_time = "";
        this.lat = 0;
        this.lon = 0;
        this.device_id = "";
        this.title = "";
        this.parameters = [];
        this.event_type = "";
        this.asset_id = "";
        this.event_value = "";
    }
    return Event;
}());
exports.Event = Event;
