import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PasswordResetPage } from '../password-reset/password-reset.page';
import { LoginPage } from '../login/login.page';
import { RegisterPage } from '../register/register.page';

const routes: Routes = [
  { path: '', component: LoginPage },
  { path: 'password-reset', component: PasswordResetPage },
  { path: 'registration', component: RegisterPage },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule { }
