"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserTask = void 0;
var UserTask = /** @class */ (function () {
    function UserTask() {
        this.id = "";
        this.task_id = "";
        this.task_name = "";
        this.task_description = "";
        this.start_time = "";
        this.end_time = "";
        this.description = "";
        this.included_in_base_price = false;
        this.measured_unit = "";
        this.budgeted_duration = 0;
        this.project_id = "";
        this.project_name = "";
        this.location_id = "";
        this.location_name = "";
        this.location_description = "";
        this.subscriber_id = "";
        this.subscriber_name = "";
        this.order = 0;
        this.notes = "";
        this.street_address = "";
        this.lat = 0.0;
        this.lon = 0.0;
        this.sla_deadline = "";
        this.last_completed_time = "";
        this.geofence = [];
    }
    return UserTask;
}());
exports.UserTask = UserTask;
