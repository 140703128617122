import { Injectable } from '@angular/core';
import { Firestore, collectionData, collection, addDoc, doc, deleteDoc, updateDoc, getDocs, orderBy, query, getDoc, where } from '@angular/fire/firestore';
import { AssetEventType } from '@shared/models/asset-event-type';
import { Observable } from 'rxjs';


@Injectable()
export class AssetEventTypeService {

    constructor(private firestore: Firestore) {
    }

    add(subscriberId: string, assetEventType: AssetEventType): any {
        addDoc(collection(this.firestore, `subscribers/${subscriberId}/asset-event-types`), Object.assign({}, assetEventType));
    }

    update(subscriberId: string, assetEventTypeId: string, assetEventType: AssetEventType): any {
        console.log("Updating");
        updateDoc(doc(this.firestore, `subscribers/${subscriberId}/asset-event-types/${assetEventTypeId}`), { ...assetEventType });
    }

    delete(subscriberId: string, assetEventTypeId: string): any {
        deleteDoc(doc(this.firestore, `subscribers/${subscriberId}/asset-event-types/${assetEventTypeId}`));
    }

    subscribeAll(subscriberId: string): any {
        return collectionData(collection(this.firestore, `subscribers/${subscriberId}/asset-event-types`), { idField: 'id' }) as Observable<AssetEventType[]>;
    }

    async get(subscriberId: string, assetEventTypeId: string): Promise<AssetEventType> {
        const ref = doc(this.firestore, `subscribers/${subscriberId}/asset-event-types/${assetEventTypeId}`);
        return Object.assign(new AssetEventType(), (await getDoc(ref)).data());
    }

    async getAll(subscriberId: string): Promise<AssetEventType[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/asset-event-types`), orderBy('name', 'desc'),);

        const data = await getDocs(q);

        const replySet: AssetEventType[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new AssetEventType(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }
}