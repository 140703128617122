"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Trip = void 0;
var Trip = /** @class */ (function () {
    function Trip() {
        this.id = "";
        this.user_id = "";
        this.vehicle_id = "";
        this.start_time = "";
        this.end_time = "";
        this.start_lat = 0.0;
        this.start_lon = 0.0;
        this.end_lat = 0.0;
        this.end_lon = 0.0;
        this.km_start = 0;
        this.km_end = 0;
        this.distance = 0;
        this.shortest_distance = 0;
        this.project = "";
        this.description = "";
        this.start_address = "";
        this.start_place_id = "";
        this.end_address = "";
        this.end_place_id = "";
        this.license_plate = "";
        this.entry_method = "";
        this.report_id = "";
        this.state = "";
        this.classified = false;
        this.approved_by_project_manager = "";
        this.approved_by_supervisor = "";
        this.approved_by_project_manager_at = "";
        this.approved_by_supervisor_at = "";
        this.passengers = 0;
        this.additional_load = 0;
        this.parking_expenses = 0;
        this.private = false;
    }
    return Trip;
}());
exports.Trip = Trip;
