"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SLA = void 0;
var SLA = /** @class */ (function () {
    function SLA() {
        this.id = "";
        this.subscriber_id = "";
        this.name = ""; // SLA name
        this.detection_execution_times = []; // List of detection-execution time pairs
        this.include_public_holidays = false;
        this.active_days = ["monday", "tuesday", "wednesday", "thursday", "friday"];
        this.carryover_to_next_day = true;
        this.created_at = ""; // Date when SLA was created
        this.created_by = ""; // User ID of creator
        this.modified_at = ""; // Date when SLA was last modified
        this.modified_by = ""; // User ID of last modifier
    }
    return SLA;
}());
exports.SLA = SLA;
