"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CostReport = void 0;
var CostReport = /** @class */ (function () {
    function CostReport() {
        this.id = "";
        this.date = "";
        this.start_period = "";
        this.end_period = "";
        this.user_id = "";
        this.comment = "";
        this.state = "";
        this.project_id = "";
        this.external_key = "";
        this.receipts = [];
        this.vat_split = [];
        this.report_path = "";
        this.payment_date = "";
        this.amount = 0;
    }
    return CostReport;
}());
exports.CostReport = CostReport;
