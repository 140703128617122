"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Subscription = void 0;
var Subscription = /** @class */ (function () {
    function Subscription() {
        this.id = "";
        this.service = "";
        this.parameters = {};
    }
    return Subscription;
}());
exports.Subscription = Subscription;
