import { Inject, Injectable } from '@angular/core';
import { UserProfile } from '@shared/models';
import { liteClient as algoliasearch } from 'algoliasearch/lite';

import { IndexWidget, Widget, } from 'instantsearch.js';
import InstantSearch from 'instantsearch.js/es/lib/InstantSearch';
import { AuthService } from './auth.service';
import { configure } from 'instantsearch.js/es/widgets';
import instantsearch from 'instantsearch.js/es';
import { ENVIRONMENT } from '../tokens/app.tokens';

@Injectable({
    providedIn: 'root',
})
export class InstantSearchService {
    public instantSearchInstance: InstantSearch;

    private userProfile!: UserProfile;

    constructor(
        @Inject(ENVIRONMENT) private environment: any,
        private authService: AuthService) {
        this.userProfile = this.authService.getUserProfileStatic();
        console.log(JSON.stringify(this.environment));
        const searchClient = algoliasearch(environment.algolia.appId, environment.algolia.apiKey);

        this.instantSearchInstance = instantsearch({
            indexName: 'projects_index',
            searchClient,
        });

        this.instantSearchInstance.addWidgets([
            configure({
                filters: `subscriberId:"${this.userProfile.subscriber_id}"`
            }),
        ]);

        this.instantSearchInstance.start();
        console.log("Algolia initialized with subscriber filtering", this.userProfile.subscriber_id);
    }


    start() {
        //     this.instantSearchInstance.start();
    }

    dispose() {
        this.instantSearchInstance.dispose();
    }

    setQuery(query: string) {
        this.instantSearchInstance.helper!.setQuery(query).search();
    }

    addWidgets(widgets: Array<Widget | IndexWidget>) {
        this.instantSearchInstance.addWidgets(widgets);
    }

    removeWidgets(widgets: Array<Widget | IndexWidget>) {
        this.instantSearchInstance.removeWidgets(widgets);
    }
}

