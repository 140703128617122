"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Clocking = void 0;
var Clocking = /** @class */ (function () {
    function Clocking() {
        this.id = "";
        this.workday_id = "";
        this.user_id = "";
        this.task_id = "";
        this.task_name = "";
        this.project_id = "";
        this.project_name = "";
        this.location_id = "";
        this.location_name = "";
        this.subscriber_id = "";
        this.exclude_from_work_time = false;
        this.included_in_contract = false;
        this.cost = 0;
        this.measured_unit = "";
        this.duration = 0;
        this.start_time = "";
        this.end_time = "";
        this.title = "";
        this.description = "";
        this.additional_data = [];
        this.media = [];
        this.approved_by_project_manager = "";
        this.approved_by_supervisor = "";
        this.approved_by_project_manager_at = "";
        this.approved_by_supervisor_at = "";
        this.asset_id = "";
        this.report_id = "";
        this.state = "";
        this.country_id = "";
        this.is_between_another = false;
    }
    return Clocking;
}());
exports.Clocking = Clocking;
