import { Injectable } from '@angular/core';
import { getStorage, ref, uploadBytesResumable } from '@angular/fire/storage';
import { FileUpload } from '@shared/models/file-upload';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private downLoadUrl = '/uploads';

  private storage: any;

  constructor() {
    this.storage = getStorage();
  }

  pushFileToStorage(subscriberId: string, fileUpload: FileUpload): any {
    const filePath = `${subscriberId}/receipts/${uuid()}_${fileUpload.file.name}`;
    const storageRef = ref(this.storage, filePath);

    try {
      const uploadTask = uploadBytesResumable(storageRef, fileUpload.file);

      return uploadTask;

    }
    catch (ex) { console.log(ex); }
  }
}