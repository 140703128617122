import { Injectable } from '@angular/core';
import { Firestore, collectionData, collection, doc, getDoc, query, orderBy, getDocs } from '@angular/fire/firestore';
import { AccountingClassifier } from '@shared/models/accounting-classifier';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable()
export class AccountingClassifierService {

    public accountingClassifiers: any[] = [];
    private issueReasonsCollection: any;
    private subject = new BehaviorSubject<any>({});
    private collection = "accounting-classifiers";

    constructor(
        private firestore: Firestore) {

        this.issueReasonsCollection = collection(this.firestore, this.collection);

        let projects = null;

        const q = query(this.issueReasonsCollection, orderBy("name", "asc"));
        //@ts-ignore
        projects = collectionData(q, { idField: 'id' }) as Observable<AccountingClassifier[]>;


        projects.subscribe(data => {
            this.subject.next(data);
        });
    }

    async get(issueReasonId: string): Promise<AccountingClassifier> {
        const d = doc(this.firestore, `${this.collection}/${issueReasonId}`);
        return (await getDoc(d)).data() as AccountingClassifier;
    }

    async getAll(): Promise<AccountingClassifier[]> {
        const q = query(collection(this.firestore, this.collection));

        const data = await getDocs(q);

        const replySet: AccountingClassifier[] = [];

        data.docs.forEach(doc => {
            const a = doc.data() as AccountingClassifier;
            a.id = doc.id;
            replySet.push(a);
        });

        return replySet;
    }
}