"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Project = void 0;
var Project = /** @class */ (function () {
    function Project() {
        this.id = "";
        this.name = "";
        this.project_type = "";
        this.project_phase = "";
        this.description = "";
        this.company_id = "";
        this.external_id = "";
        this.project_code = "";
        this.code = "";
        this.user_id_list = [];
        this.team_id_list = [];
        this.manager_id_list = [];
        this.start_date = "";
        this.end_date = "";
        this.team_id_list = [];
        this.available_in_mobile = true;
        this.attributes = [];
        this.created_by = "";
        this.created_at = "";
        this.modified_by = "";
        this.modified_at = "";
    }
    return Project;
}());
exports.Project = Project;
