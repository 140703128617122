import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
//import { GeocoderResponse } from '../interfaces/geocoder-response';

@Injectable({
    providedIn: 'root',
})
export class GeocodingService {

    constructor(private http: HttpClient) { }



}