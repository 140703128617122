"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetType = void 0;
var AssetType = /** @class */ (function () {
    function AssetType() {
        this.id = "";
        this.name = "";
        this.sub_type = "";
        this.description = "";
        this.type = "";
    }
    return AssetType;
}());
exports.AssetType = AssetType;
