import { Injectable } from '@angular/core';
import { Firestore, collectionData, collection, addDoc, doc, deleteDoc, updateDoc, getDocs, query, orderBy } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { where } from 'firebase/firestore';
import { CalendarEvent } from '@shared/models/calendar-event';

@Injectable()
export class CalendarService {

    constructor(private firestore: Firestore) {
    }

    async add(subscriberId: string, calendarEvent: CalendarEvent): Promise<string> {
        return await addDoc(collection(this.firestore, `subscribers/${subscriberId}/calendar-events`), Object.assign({}, calendarEvent)).then(d => {
            return d.id;
        });
    }

    update(subscriberId: string, calendarId: string, calendarEvent: CalendarEvent): any {
        updateDoc(doc(this.firestore, `subscribers/${subscriberId}/calendar-events/${calendarId}`), { ...calendarEvent });
    }

    delete(subscriberId: string, calendarId: string): any {
        deleteDoc(doc(this.firestore, `subscribers/${subscriberId}/calendar-events/${calendarId}`));
    }

    getAllLive(subscriberId: string) {
        return collectionData(collection(this.firestore, `subscribers/${subscriberId}/calendar-events`), { idField: 'id' }) as Observable<CalendarEvent[]>;
    }

    async getAll(subscriberId: string): Promise<CalendarEvent[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/calendar-events`), orderBy('name', 'desc'),);

        const data = await getDocs(q);

        const replySet: CalendarEvent[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new CalendarEvent(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

    async getByAsset(subscriberId: string, assetId: string): Promise<CalendarEvent[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/calendar-events`), where('asset_id_list', "array-contains", assetId));

        const data = await getDocs(q);

        const replySet: CalendarEvent[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new CalendarEvent(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

    async getByUser(subscriberId: string, userId: string): Promise<CalendarEvent[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/calendar-events`), orderBy('start_time', 'desc'), where('user_id_list', "array-contains", userId));

        const data = await getDocs(q);

        const replySet: CalendarEvent[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new CalendarEvent(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

    getByAssetLive(subscriberId: string, assetId: string) {
        console.log(`Initialize query for asset ${assetId} of subscriber ${subscriberId}`);
        return collectionData(query(collection(this.firestore, `subscribers/${subscriberId}/calendar-events`), where('asset_id_list', "array-contains", assetId)), { idField: 'id' }) as Observable<CalendarEvent[]>;
    }

    getByUserLive(subscriberId: string, userId: string) {
        console.log(`Initialize query for user ${userId} of subscriber ${subscriberId}`);
        return collectionData(query(collection(this.firestore, `subscribers/${subscriberId}/calendar-events`), where('user_id_list', "array-contains", userId), orderBy('start_time'), where('end', ">", new Date().toISOString())), { idField: 'id' }) as Observable<CalendarEvent[]>;
    }
}