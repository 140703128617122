import { Injectable } from '@angular/core';
import { Firestore, collectionData, collection, addDoc, doc, deleteDoc, updateDoc, getDocs, orderBy, query, getDoc, where } from '@angular/fire/firestore';
import { AssetType } from '@shared/models/asset-type';
import { Observable } from 'rxjs';


@Injectable()
export class AssetTypeService {

    constructor(private firestore: Firestore) {
    }

    add(subscriberId: string, assetType: AssetType): any {
        addDoc(collection(this.firestore, `subscribers/${subscriberId}/asset-types`), Object.assign({}, assetType));
    }

    update(subscriberId: string, assetTypeId: string, assetType: AssetType): any {
        console.log("Updating");
        updateDoc(doc(this.firestore, `subscribers/${subscriberId}/asset-types/${assetTypeId}`), { ...assetType });
    }

    delete(subscriberId: string, assetTypeId: string): any {
        deleteDoc(doc(this.firestore, `subscribers/${subscriberId}/asset-types/${assetTypeId}`));
    }

    subscribeAll(subscriberId: string): any {
        return collectionData(collection(this.firestore, `subscribers/${subscriberId}/asset-types`), { idField: 'id' }) as Observable<AssetType[]>;
    }

    async get(subscriberId: string, assetTypeId: string): Promise<AssetType> {
        const ref = doc(this.firestore, `subscribers/${subscriberId}/asset-types/${assetTypeId}`);
        return Object.assign(new AssetType(), (await getDoc(ref)).data());
    }

    async getAll(subscriberId: string): Promise<AssetType[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/asset-types`), orderBy('name', 'asc'),);

        const data = await getDocs(q);

        const replySet: AssetType[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new AssetType(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

    async getAssetTypesByType(subscriberId: string, type: string): Promise<AssetType[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/asset-types`), orderBy('name', 'desc'), where("type", "==", type));

        const data = await getDocs(q);

        const replySet: AssetType[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new AssetType(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }
}