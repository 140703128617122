"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVatCountryText = exports.getCountryClasses = exports.vatClasses = void 0;
exports.vatClasses = [{
        country: 'fi',
        classes: [
            { id: 'NATIONAL_255', name: "25,5" },
            { id: 'NATIONAL_24', name: "24" },
            { id: 'NATIONAL_14', name: "14" },
            { id: 'NATIONAL_10', name: "10" },
            { id: 'NATIONAL_0', name: "0" },
            { id: 'EU_0_SERVICE', name: "EU Palvelu" },
            { id: 'EU_0_MATERIAL', name: "EU Tavara" },
            { id: 'GLOBAL_0_SERVICE', name: "EU Ulkopuolinen palvelu" },
            { id: 'GLOBAL_0_MATERIAL', name: "EU Ulkopuolinen tavara" },
        ]
    }];
var getCountryClasses = function (country) {
    var countryData = exports.vatClasses.find(function (c) { return c.country === country; });
    if (countryData !== undefined) {
        return countryData.classes;
    }
    console.error("VAT data missing when searching with ".concat(country));
    return [];
};
exports.getCountryClasses = getCountryClasses;
var getVatCountryText = function (country, id) {
    if (id !== '' && id !== undefined) {
        var countryData = exports.vatClasses.find(function (c) { return c.country === country; });
        if (countryData !== undefined) {
            var a = countryData.classes.find(function (a) { return a.id === id; });
            if (a !== undefined) {
                return a.name;
            }
            else {
                console.error("VAT data missing when searching with ".concat(country, " and ").concat(id));
            }
        }
        else {
            console.error("VAT data missing when searching with ".concat(country, " and ").concat(id));
        }
    }
    return "N/A";
};
exports.getVatCountryText = getVatCountryText;
