<form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <ion-item lines="full">
        <ion-input formControlName="email" name="email" type="text" required label="Sähköposti"></ion-input>
    </ion-item>

    <ion-item lines="full">
        <ion-input formControlName="password" name="password" type="password" required label="Salasana"></ion-input>
    </ion-item>

    <ion-button style="margin-top: 20px" class="submit-btn" type="submit" expand="full" fill="outline">Kirjaudu
    </ion-button>

    <ion-button style="margin-top: 20px;" color="medium" expand="full" fill="outline" (click)="requestPassword()">
        Salasana unohtunut
    </ion-button>

    <ion-button *ngIf="allowRegistration" style="margin-top: 20px;" color="medium" expand="full" fill="outline"
        (click)="createAccount()">
        Luo ilmainen tili !
    </ion-button>
</form>