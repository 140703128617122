import { Injectable } from '@angular/core';
import { Firestore, collection, addDoc, doc, deleteDoc, updateDoc, getDocs, orderBy, query } from '@angular/fire/firestore';
import { Task } from '@shared/models/task';

@Injectable()
export class TaskService {

    constructor(private firestore: Firestore) {
    }

    async add(subscriberId: string, projectId: string, task: Task): Promise<string> {
        return await addDoc(collection(this.firestore, `subscribers/${subscriberId}/projects/${projectId}/tasks`), Object.assign({}, task)).then(d => {
            return d.id;
        });
    }

    update(subscriberId: string, projectId: string, taskId: string, task: Task): any {
        updateDoc(doc(this.firestore, `subscribers/${subscriberId}/projects/${projectId}/tasks/${taskId}`), { ...task });
    }

    delete(subscriberId: string, projectId: string, taskId: string): any {
        deleteDoc(doc(this.firestore, `subscribers/${subscriberId}/projects/${projectId}/tasks/${taskId}`));
    }

    async getAll(subscriberId: string, projectId: string): Promise<Task[]> {
        const path = `subscribers/${subscriberId}/projects/${projectId}/tasks`;
        console.log(path);
        const q = query(collection(this.firestore, path), orderBy('name', 'asc'),);

        const data = await getDocs(q);

        const replySet: Task[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new Task(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

}