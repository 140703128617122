import { Injectable } from '@angular/core';
import { Firestore, collectionData, collection, addDoc, doc, deleteDoc, updateDoc, getDocs, orderBy, query, getDoc, where } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Location } from '@shared/models/location';


@Injectable()
export class LocationService {

    constructor(private firestore: Firestore) {
    }

    async add(subscriberId: string, location: Location): Promise<string> {
        return await addDoc(collection(this.firestore, `subscribers/${subscriberId}/locations`), Object.assign({}, location)).then(d => {
            return d.id;
        });
    }

    update(subscriberId: string, locationId: string, location: Location): any {
        console.log("Updating");
        updateDoc(doc(this.firestore, `subscribers/${subscriberId}/locations/${locationId}`), { ...location });
    }

    delete(subscriberId: string, locationId: string): any {
        deleteDoc(doc(this.firestore, `subscribers/${subscriberId}/locations/${locationId}`));
    }

    subscribeAll(subscriberId: string): any {
        return collectionData(collection(this.firestore, `subscribers/${subscriberId}/locations`), { idField: 'id' }) as Observable<Location[]>;
    }

    async get(subscriberId: string, locationId: string): Promise<Location> {
        const ref = doc(this.firestore, `subscribers/${subscriberId}/locations/${locationId}`);
        return Object.assign(new Location(), (await getDoc(ref)).data());
    }

    async getAll(subscriberId: string): Promise<Location[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/locations`), orderBy('name', 'asc'),);

        const data = await getDocs(q);

        const replySet: Location[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new Location(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

    async getByType(subscriberId: string, type: string): Promise<Location[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/locations`), orderBy('name', 'asc'), where("type", "==", type));

        const data = await getDocs(q);

        const replySet: Location[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new Location(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

    async getByProject(subscriberId: string, projectId: string): Promise<Location[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/locations`), orderBy('name', 'asc'), where("project_id", "==", projectId));

        const data = await getDocs(q);

        const replySet: Location[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new Location(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }
}