"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Team = void 0;
var Team = /** @class */ (function () {
    function Team() {
        this.id = "";
        this.name = "";
        this.description = "";
        this.code = "";
        this.external_id = "";
        this.member_id_list = [];
        this.manager_id_list = [];
        this.home_base = "";
        this.created_by = "";
        this.created_at = "";
        this.modified_by = "";
        this.modified_at = "";
    }
    return Team;
}());
exports.Team = Team;
